import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import GoogleSignInPlugin from "vue3-google-signin";
import PrimeVue from "primevue/config";
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

import "./index.css"; // need to import tailwind before primevue

//import "primevue/resources/themes/lara-light-indigo/theme.css";
import './assets/themes/mytheme/theme.scss';
import 'primevue/resources/primevue.min.css'; 
import 'primeicons/primeicons.css';

import '@/assets/layout/layout.scss';

const pinia = createPinia();
const app = createApp(App);

app.use(PrimeVue);

app.use(ToastService);
app.directive('tooltip', Tooltip);

const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;

app.use(GoogleSignInPlugin, {
  clientId: googleClientId,
});

app.use(router);
app.use(pinia);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

app.config.globalProperties.$axios = axios;

app.mount("#app");
