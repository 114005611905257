import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.user,
    isAdministrator: (state) => state.user?.role === "admin",
    hasRegistered: (state) => state.user?.hasRegistered,
    companyQuizSubmitted: (state) => !!state.user?.companyQuizSubmitted,
  },
  actions: {
    async logout() {
      try {
        await axios.post("/api/auth/logout");
        this.user = null;
        router.push("/");
      } catch (error) {
        console.error("Logout failed:", error);
        throw error;
      }
    },
    async checkAuth() {
      try {
        const response = await axios.get("/api/auth/user-data");
        this.user = {
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          role: response.data.role,
          email: response.data.email,
          companyName: response.data.companyName,
          companyDomain: response.data.companyDomain,
          adminFirstStage: !response.data.membersInvited,
          hasRegistered: response.data.hasRegistered,
          companyQuizSubmitted: response.data?.companyQuizSubmitted,
        };
      } catch (error) {
        this.user = null;
      }
    },
    async deleteAccount() {
      try {
        await axios.post("/api/auth/delete-account");
        this.user = null;
        router.push("/");
      } catch (error) {
        console.error("Account deletion failed:", error);
        throw error;
      }
    },
  },
});
