import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/authStore";

const routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "home-page" */ "@/views/HomePage.vue"),
  },
  {
    path: "/registration",
    component: () =>
      import(
        /* webpackChunkName: "register-page" */ "@/views/RegisterPage.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/quiz",
    component: () =>
      import(/* webpackChunkName: "quiz-page" */ "@/views/QuizPage.vue"),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard-page" */ "@/views/DashboardPage.vue"
      ),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/userdashboard",
    component: () =>
      import(
        /* webpackChunkName: "user-dashboard-page" */ "@/views/UserDashboardPage.vue"
      ),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  await authStore.checkAuth();

  const isAuthenticated = authStore.isAuthenticated;
  const hasRegistered = authStore.hasRegistered;
  const isAdministrator = authStore.isAdministrator;
  const companyQuizSubmitted = authStore.companyQuizSubmitted;

  if (to.path === "/") {
    if (isAuthenticated) {
      if (isAdministrator) {
        if (hasRegistered) {
          if (companyQuizSubmitted) {
            return next("/dashboard");
          } else {
            return next("/quiz");
          }
        } else {
          return next("/registration");
        }
      } else if (hasRegistered) {
        return next("/userdashboard");
      } else {
        return next("/registration");
      }
    } else {
      // User is not authenticated, stay on the login page ("/")
      return next();
    }
  } else {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth && !isAuthenticated) {
      return next("/");
    }

    const requiresAdmin = to.matched.some(
      (record) => record.meta.requiresAdmin
    );
    if (requiresAdmin && !isAdministrator) {
      return next("/userdashboard");
    }

    if (!requiresAdmin && isAdministrator && to.path === "/userdashboard") {
      return next("/dashboard");
    }

    if (
      (to.path === "/registration" && hasRegistered) ||
      (to.path === "/quiz" && companyQuizSubmitted)
    ) {
      return next(isAdministrator ? "/dashboard" : "/userdashboard");
    }
  }

  next();
});

export default router;
